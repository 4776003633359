<template>
  <CommonButton
    type="button"
    :variant="variant"
    class="lg:w-fit lg:min-w-[112px]"
    data-testid="button-contact-us"
    @click="handleClick"
  >
    {{ text }}
  </CommonButton>
</template>

<script setup lang="ts">
import CommonButton from '~/components/common/Button.vue'
import { getServices } from '~/constants/services'
import type { VARIANT_CLASSES } from '@/constants/variant-button-classes'

const props = withDefaults(
  defineProps<{
    services?: Array<any> | Record<string, any> | null
    text?: string
    modalName?: string
    variant?: keyof typeof VARIANT_CLASSES.BASE
  }>(),
  {
    services: () => null,
    text: 'Contact Us',
    modalName: 'contact',
    variant: 'primary',
  },
)

const { $modal, $rudderstack } = useNuxtApp()

const handleClick = () => {
  $rudderstack.trackContentGatedInteraction()

  if (props.services === null) {
    return $modal.open(props.modalName)
  }

  if (Array.isArray(props.services) && props.services?.length > 0) {
    const initialValues = getServices([...props.services])
    $modal.setInitialFields(initialValues)
    return $modal.open(props.modalName)
  }

  $modal.setInitialFields(props.services)
  return $modal.open(props.modalName)
}
</script>
